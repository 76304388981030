<template>
  <div style="height:100%"
    <znl-pub-stock-pricing
        ref="pubSotckPricing"
        :init-data="true"
        @page-loading="setClientSelectLoading"
      ></znl-pub-stock-pricing>
  </div>
</template>
<script>
import znlPubStockPricing from '@c_modules/Trading/PubSotckPricing'
export default {
  name: 'trading-center',
  components: {
    znlPubStockPricing
  },
  data () {
    return {
      pageLoading: true
    }
  },
  methods: {
    setClientSelectLoading(val) {
      this.pageLoading = val
    },
  },
  created () {}
}
</script>
<style lang="scss">
</style>

